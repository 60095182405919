var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'},[_vm._v(" "+_vm._s(_vm.labelAttachment)+" ")]),_c('div',{staticClass:"mt-3"},[_vm._l((_vm.row.file_key),function(i){return _c('div',{key:i,class:i == 0 ? 'hidden' : '',attrs:{"id":'frm2_' + i}},[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"row col-11"},[_c('div',{staticClass:"col-12 form-group"},[_c('label',{class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'txt-left'},[_vm._v(" "+_vm._s(_vm.$t("view.title"))+" ("+_vm._s(_vm.$t("app.arabic"))+") ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.certificate_title_ar[i]),expression:"row.certificate_title_ar[i]"}],staticClass:"form-control",attrs:{"id":"input","type":"text"},domProps:{"value":(_vm.row.certificate_title_ar[i])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.row.certificate_title_ar, i, $event.target.value)}}}),_c('label',{class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'txt-left'},[_vm._v(" "+_vm._s(_vm.$t("view.title"))+" ("+_vm._s(_vm.$t("app.english"))+") ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.certificate_title_en[i]),expression:"row.certificate_title_en[i]"}],staticClass:"form-control",attrs:{"id":"input","type":"text"},domProps:{"value":(_vm.row.certificate_title_en[i])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.row.certificate_title_en, i, $event.target.value)}}}),_c('label',{staticClass:"control-label",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''},[_vm._v(" "+_vm._s(_vm.$t("app.upload_attachment"))+" ")]),_c('input',{staticClass:"form-control",attrs:{"type":"file"},on:{"change":function($event){return _vm.onFileChange($event, i)}}}),(_vm.row.files[i] && _vm.$props.attachment_key)?_c('p',{staticStyle:{"margin-top":"5px"}},[_c('a',{staticClass:"btn btn-success btn-sm",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : '',staticStyle:{"color":"#fff"},attrs:{"href":_vm.row.files[i],"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("app.show_attachment"))+" ")])]):_vm._e()])]),_c('div',{staticClass:"col-1"},[(i != 0)?_c('div',{staticClass:"col-12 form-group"},[_c('button',{staticClass:"btn btn-danger btn-rounded btn-sm btn-bold ui-mt30",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeOption(i)}}},[_vm._m(0,true)])]):_vm._e()]),_vm._m(1,true)])])}),_c('div',{staticClass:"col-12 text-center"},[_c('button',{staticClass:"btn btn-primary btn-rounded btn-bold pull-right",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.addMore()}}},[_c('span',{staticClass:"icon-holder"},[_c('i',{staticClass:"mdi mdi-plus-circle"}),_vm._v(" "+_vm._s(_vm.$t("app.add_new"))+" ")])])])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon-holder"},[_c('i',{staticClass:"mdi mdi-delete"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr'),_c('br')])
}]

export { render, staticRenderFns }